// extracted by mini-css-extract-plugin
export var badge = "Badge__badge__EWW0H";
export var colorBareMetal = "Badge__colorBareMetal__e9uPF";
export var colorCloud = "Badge__colorCloud__ovF58";
export var colorColocation = "Badge__colorColocation__mfyy3";
export var colorDefault = "Badge__colorDefault__EtjfF";
export var colorInternet = "Badge__colorInternet__MnO94";
export var colorNetwork = "Badge__colorNetwork___y2ys";
export var column = "Badge__column__YqGMN";
export var flex = "Badge__flex__YI6i1";
export var flexColumn = "Badge__flexColumn__ZGauC";
export var gap1 = "Badge__gap1__Iv5Me";
export var gap2 = "Badge__gap2__LGpeY";
export var gap3 = "Badge__gap3__NjlH8";
export var gap4 = "Badge__gap4__HRtbm";
export var gap5 = "Badge__gap5__WuXgH";
export var row = "Badge__row__sRcbE";
export var wrapper = "Badge__wrapper__BnseJ";